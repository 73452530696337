import { createStore } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import { collections } from './collections.module'
import { supporters } from './supporters.module'
import { records } from './records.module'
import { locations } from './locations.module'
import { users } from './users.module'


export default createStore({
  state: {
    token: localStorage.getItem('token') || '',
    user_id: localStorage.getItem('user_id') || -1,
    user_permissions: localStorage.getItem('user_perm') || -1,
    status: 'none',
    loading: false,
    error:{},
    flash_msg:{},
    img_msg:''
  },
  mutations,
  actions,
  getters: {
    isAuthenticated: state => {
      return !!state.token
    }
  },
  modules: {
   collections,
   supporters,
   records,
   locations,
   users
  }
})
