import http from "../services/http-common"

export const supporters = {
    namespaced: true,
    state: {
        all: {

        }
    },
    mutations: {
        getAllRequest(state) {
            state.all = { loading: true }
        },
        getAllSuccess(state, supporters) {
            state.all = { items: supporters }
        },
        getAllFailure(state, error) {
            state.all = { error }
        },
        addNew(state, supporter) {
            if (state.all.items) {
                state.all.items.push(supporter)
            }
            else {
                state.all = {
                    items: [supporter]
                }
            }
        },
        destroy(state, idx){
            if(state.all.items){
                //var idx = state.all.items.findIndex(supporter);
                state.all.items.splice(idx,1);
            }
        }
    },
    actions: {
        getAll({ commit }) {
            //console.log("get all")
            return http.get('/supporters')
                .then(response => {
                    commit('getAllSuccess', response.data)
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },

        create({ commit }, supporter) {
            http.post("/supporters", supporter)
                .then(response => {
                    //console.log("SUCCESS: " + response.data)
                    commit('addNew', response.data)
                })
                .catch(function (error) {
                    let _err = {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers
                    }
                    //console.log(_err)
                    commit('getAllFailure', _err)
                })
        },

        destroy({commit},supporter){
            var id = supporter.id
            return http.delete("/supporters/"+supporter.id)
            .then(response => {
                //console.log("Successfully deleted!"+response.data)
                commit('destroy', id)
                return response.data
            })
            .catch(function(error){
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        },

        update({commit}, supporter){
            return http.put("/supporters/"+supporter.id, {supporter: supporter})
            .then(response => {
                //console.log("Updated successfully!: "+response.data)
                return response.data
            })
            .catch(function(error){
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        }
    },
    getters:{
        getAllOrdered(state){
            //alphabetize collections and return
            return state.all
        }
    }

}