<template>
  <div>
    <nav-bar />
    <!-- <flash-message /> -->
    <loading-spinner v-if="loading" />
    <div v-show="!loading">
      <router-view />
    </div>

<!-- Footer for copyright -->
      <div class="fixedFooter is-flex">
        <p class="mx-3 my-auto has-text-left is-credit is-size-7 has-text-white">© 2022 Santa Monica History Museum, All Rights Reserved</p>
      </div>

  </div>
</template>

<script>
//import FlashMessage from './components/FlashMessage.vue'
import NavBar from "./components/NavBar.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue";

export default {
  components: 
  { 
    //FlashMessage,
   NavBar, 
   LoadingSpinner },
  methods: {},
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #292929;

    &.router-link-exact-active {
      color: #3184c6;
    }
  }
}
</style>
