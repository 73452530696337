import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

require('./styles/main.scss');

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faArrowLeft,
  faArrowRight,
  faSave,
  faTimes,
  faTimesCircle,
  faPlusCircle,
  faEdit,
  faSearch,
  faUpload,
  faImage,
  faTrashAlt,
  faDownload,
  faQuestionCircle,
  faPen
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faArrowLeft,
  faArrowRight,
  faSave,
  faTimes,
  faTimesCircle,
  faPlusCircle,
  faEdit,
  faSearch,
  faUpload,
  faImage,
  faTrashAlt,
  faDownload,
  faQuestionCircle,
  faPen);

router.beforeEach((to, from, next) => {
    if (to.name === 'Admin' && !store.getters.isAuthenticated) next({ name: 'Login' })
    else next()
  })

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
