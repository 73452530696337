<template>
    <div v-if="showFlashMsg" id="flash_alert">
        <div v-bind:class="flash_class" class="is-text">
            <button class="delete is-large" v-on:click="dismiss"></button>
            {{flash_msg}}
        </div>
    </div>
</template>
<style scoped>
#flash_alert{
    position: absolute;
    width:100%;
    z-index: 3;
}
</style>
<script>
import {mapState} from 'vuex';

export default {
    watch:{
        $route(){
            //hides flash message if route is changed
            this.dismiss();
        }
    },
    computed: mapState({
        flash_msg: 'flash_msg',
        status: 'status',
        flash_class: function(){
            return{
                'notification': true,
                'is-danger': this.status === 'error',
                'is-success': this.status === 'success'
            }
        },
        showFlashMsg: function(){
            return this.status != 'none'
        }
    }),
    methods:{
        dismiss(){
            this.$store.commit('DISMISS_ERROR');
        }
    }
}
</script>