import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/collections',
    name: 'Collections',
    component: () => import('../views/collections/Collections.vue')
  },
  {
    path: '/collections/:id',
    name: 'Collection',
    component: () => import('../views/collections/Collection.vue'),
  },
  {
    path: '/collections/:id/locations/:location_id/edit',
    name: 'Edit Location',
    component: () => import('../views/locations/EditLocation.vue')
  },
  {
    path: '/collections/:id/locations/:location_id/records',
    name: 'Records',
    // regex:{
    //   keys:[
    //     {
    //       name: "record_id",
    //       optional: true
    //     }
    //   ]
    // },
    component: () => import('../views/locations/Gallery.vue')
  },
  //DEPRECATED replaced by records view
  // {
  //   path: '/collections/:id/records/:id',
  //   name: 'Record',
  //   component: () => import('../views/records/Record.vue')
  // },
  {
    path:'/all-records',
    name: 'AllRecords',
    component: () => import('../views/records/AllRecords.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LogIn.vue')
  },
  // {
  //   path: '/login/register',
  //   name: 'Register',
  //   component:() => import('../views/Register.vue')
  // },
  {
    path: '/supporters',
    name: 'Supporters',
    component: () => import('../views/Supporters.vue')
  },
  {
    path:'/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
