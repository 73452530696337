export default function authHeader(){
    let auth = localStorage.getItem('token')

    if(auth){
        return {
            "Content-type": "application/json",
            Authorization: auth
        }
    }else{
        return {
            "Content-type": "application/json"
        }
    }
}