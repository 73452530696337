<template>
  <div class="mt-6 is-fullheight">
    <section class="hero is-large is-dark has-background">
      <img
        class="hero-background"
        
        src="@/assets/homeBkg.jpg"
      />
      <div class="hero-body" style="min-height:300px"></div>
    </section>
    <p class="is-pulled-right is-credit mr-3 is-size-7">California Incline, by J.M. Garrison, circa 1910, Santa Monica History Museum Collection (2018.FIC.1.51)</p>
    <div class="section is-centered">
      <p class="column is-half is-offset-3 mb-6 is-text">
        Welcome to the Santa Monica History Museum's history explorer locations!<br><br>
        You may discover the Museum's amazing collection of archival photography by clicking either the View by Collection or View by Record buttons below.<br><br>
        If you're out and about in the city, look for our History Happened Here! QR decals and scan them to view the museum's photographs related to that location. Enjoy!<br><br>
        Learn more about the museum at our website: <a href="https://santamonicahistory.org/">www.santamonicahistory.org</a> <br><br>
        For museum staff or volunteer users, please use the login button above. 
      </p>
      <div class="buttons is-centered">
        <router-link
          class="button is-link is-flex-mobile home-btn is-sharp is-text"
          style="text-decoration:none"
          to="/collections"
          >VIEW BY COLLECTION</router-link
        >

        <router-link
          class="button is-link is-flex-mobile home-btn is-sharp is-text"
          style="text-decoration:none"
          to="/all-records"
          >VIEW BY RECORD</router-link
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.home-btn {
  width: 100%;
  max-width: 500px;
}

.has-background {
  position: relative;
  overflow: hidden;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
</style>
<script>
import {logoutIfTokenExpired} from "../services/http-common";
export default {
  name: "Home",
  // components: {
  //   HelloWorld
  // }
  mounted(){
    logoutIfTokenExpired();
  }
};
</script>
