import http from "../services/http-common"

export const collections = {
    namespaced: true,
    state: {
        all: {
           
        },
        activeCollection:{}
    },
    mutations: {
        getAllRequest(state) {
            state.all = { loading: true }
        },
        getAllSuccess(state, collections) {
            state.all = { items: collections }
        },
        getAllFailure(state, error) {
            state.all = { error }
        },
        addNew(state, collection) {
            if (state.all.items) {
                state.all.items.push(collection)
            }
            else {
                state.all = {
                    items: [collection]
                }
            }
        },
        destroy(state, idx){
            if(state.all.items){
                //var idx = state.all.items.findIndex(collection);
                state.all.items.splice(idx,1);
            }
        },
        setActiveCollection(state, collection){
            state.activeCollection = collection
            
        },
        setRecords(state, records){
            state.records = records
        }
    },
    actions: {
        getAll({ commit }) {
            return http.get('/collections')
                .then(response => {
                    commit('getAllSuccess', response.data)
                    return response.data;
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        setCollection({commit}, collection_id){
            return http.get('/collections/' + collection_id)
                .then(response => {
                    commit('setActiveCollection', response.data)
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getCollectionRecords({commit},collection_id){
            return http.get('/collections/'+collection_id+'/records')
                .then(response=>{
                    commit('setRecords', response.data)
                    return response.data
                })
                .catch(function (error) {
                    // let _err = {
                    //     data: error.response.data,
                    //     status: error.response.status,
                    //     headers: error.response.headers
                    // }
                    //console.log(error)
                    commit('getAllFailure', error)
                })
        },
        create({ commit }, collection) {
            return http.post("/collections", collection)
                .then(response => {
                    //console.log("SUCCESS: " + response.data.id)
                    commit('addNew', response.data)
                    commit('setActiveCollection', response.data)
                    return response.data.id
                })
                .catch(function (error) {
                    let _err = {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers
                    }
                    //console.log(_err)
                    commit('getAllFailure', _err)
                })
        },

        update({commit}, collection){
            //console.log("before "+collection.description)
            http.put("/collections/"+collection.id, {collection: collection })
            .then(response=> {
                console.log("SUCCESS: "+response.data)
            })
            .catch(function (error) {
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        },

        destroy({commit},collection){
            var id = collection.id
            return http.delete("/collections/"+collection.id)
            .then(() => {
                //console.log(response.data)
                commit('destroy', id)
            })
            .catch(function(error){
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        }
    },
    getters:{
        getMobileCollections: state =>{
            //console.log("getting mobile locations")
            if(state.all.items && state.all.items.length > 0){
                return state.all.items.filter(c => c.mobile_show);
            }
            else return []
        }
    }
}