import http from "../services/http-common";

export const locations ={
    namespaced: true,
    state:{
        all:{},
        active:{}
    },
    mutations:{
        getAllRequest(state) {
            state.all = { loading: true }
        },
        getAllSuccess(state, locations) {
            state.all = { items: locations }
        },
        getAllFailure(state, error) {
            state.all = { error }
        },
        setActiveLocation(state, location){
            state.active = location
        },
        addNew(state, location) {
            if (state.all.items) {
                state.all.items.push(location)
            }
            else {
                state.all = {
                    items: [location]
                }
            }
        },
        destroy(state, idx){
            if(state.all.items){
                state.all.items.splice(idx,1);
            }
        },
    },
    actions: {
        getCollectionLocations({commit}, collectionId){
            return http.get('/collections/' + collectionId +'/locations/')
                .then(response =>{
                    commit('getAllSuccess', response.data)
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getLocationFromRoute({commit}, route){
            return http.get(route)
            .then(response =>{
                commit('setActiveLocation', response.data)
                return response.data
            })
            .catch(function (error) {
                commit('getAllFailure', error)
                commit('RAISE_ERROR', error, {root: true})
            })
        },
        getLocationFromId({commit}, id){
            return http.get("/locations/"+id)
            .then(response =>{
                commit('setActiveLocation', response.data)
                //console.log(response.data);
                return response.data
            })
            .catch(function (error) {
                commit('getAllFailure', error)
                commit('RAISE_ERROR', error, {root: true})
            })
        },
        create({ commit }, {collectionId, location}) {
            return http.post("/collections/"+collectionId+"/locations", location)
                .then(response => {
                    //console.log("SUCCESS: " + response.data.id)
                    commit('addNew', response.data)
                    //commit('setActiveCollection', response.data)
                    return response.data
                })
                .catch(function (error) {
                    let _err = {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers
                    }
                    //console.log(_err)
                    commit('getAllFailure', _err)
                })
        },
        destroy({commit},location){
            var id = location.id
            http.delete("/collections/"+location.collection_id+"/locations/"+id)
            .then(() => {
                //console.log(response.data)
                commit('destroy', id)
            })
            .catch(function(error){
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        },
        update({commit}, location){
            http.put("/collections/"+location.collection_id+"/locations/"+location.id, {location: location })
            .then(response=> {
                console.log("SUCCESS: "+response.data)
            })
            .catch(function (error) {
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        },
    },
    getters:{
        getMobileLocations: state =>{
            //console.log("getting mobile locations")
            if(state.all.items && state.all.items.length > 0){
                return state.all.items.filter(loc => loc.show_mobile);
            }
            else return []
        }
    }
}