<template>
<div class="navbar fixedHeader">
    <nav class="navbar is-link dropshadow fixedHeader" role="navigation" aria-label="dropdown navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://santamonicahistory.org/">
          <img src="../assets/smhm_logo.jpg">
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" v-on:click="showNav = !showNav" v-bind:class="{ 'is-active' : showNav }">
          <span aria-hidden="true" ></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" 
            v-bind:class="{ 'is-active has-background-link' : showNav }"> <!-- When active also set background to link to match designs-->
        <div class="navbar-start">          
          <router-link to="/" @click="showNav = false" class="navbar-item has-text-white is-text">Home</router-link>
          <router-link to="/admin" @click="showNav = false" class="navbar-item has-text-white is-text" v-if="loggedIn">Admin</router-link>
          <router-link to="/supporters" @click="showNav = false" class="navbar-item has-text-white is-text">Supporters</router-link>
          <router-link to="/collections" @click="showNav = false" class="navbar-item has-text-white is-text">Collections</router-link>
          <router-link to="/all-records" @click="showNav = false" class="navbar-item has-text-white is-text">Records</router-link>
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons is-centered">
              
              <!-- <a class="button is-light-blue is-sharp" v-if="!loggedIn" @click="showNav = false">
                <router-link to="/login" class="is-text">Login</router-link>
              </a> -->

              <a class="button is-light is-sharp is-text" v-if="loggedIn" @click="signout">
                Log Out
              </a>
            </div>
          </div>
        </div>
      </div>

      
</nav>
<flash-message class="mt-6"/>
</div>
</template>

<script>
import FlashMessage from './FlashMessage.vue'
export default {
  components: { FlashMessage},
    data(){
        return{
            showNav:false
        }

    },
    methods:{
      async signout(){
        this.showNav = false;
        await this.$store.dispatch("logout");
        this.$router.push('/');
      }
    },
    computed:{
        loggedIn(){
            return this.$store.getters.isAuthenticated
        }
    }
}
</script>