<template>
  <div>
    <div class="section loader-bg">
      <div class="rows is-vcentered">
        <div class="row is-centered is-full">
          <div class="loader is-loading is-centered"></div>
        </div>
        <div class="row is-full">
          <div class="section">
            <div class="content is-italic">
              <p>Loading Data, Please Wait</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.loader-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  opacity: 1;
  z-index: 5;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader-wrapper {
  z-index: 6;
}

.loader {
  height: 100px;
  width: 100px;
  left: 40%;
  border: 10px solid #1957A7;
  border-right-color: transparent;
  border-top-color: transparent;
}

.rows {
  display: flex;
  flex-direction: column;
}
</style>
