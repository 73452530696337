import http from "../services/http-common"


export const records = {
    namespaced: true,
    state: {
        all: {},
        curRecord:{}
    },
    mutations: {
        getAllRequest(state) {
            state.all = { loading: true }
        },
        getAllSuccess(state, records) {
            state.all = { items: records }
        },
        getAllFailure(state, error) {
            state.all = { error }
        },
        addNew(state, record) {
            if (state.all.items) {
                state.all.items.push(record)
            }
            else {
                state.all = {
                    items: [record]
                }
            }
        },
        destroy(state, idx){
            if(state.all.items){
                const filter = state.all.items.filter((item)=>{
                    return item.id !== idx
                })

                state.all.items = filter;
            }

            //console.log("removing from store")
        },
        setRecord(state, record){
            state.curRecord = record
        }
    },
    actions: {
        getRecord({ commit },route) {
            return http.get(route)
                .then(response => {
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getAll({ commit },location) {
            const path = '/collections/' + location.collection_id + '/locations/' + location.id + '/records/'
            return http.get(path)
                .then(response => {
                    //console.log("got all records")
                    commit('getAllSuccess', response.data)
                    //console.log(response.data);
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getAllBulk({ commit }) {
            const path = '/bulk-records';
            return http.get(path)
                .then(response => {
                    //console.log("got all records")
                    commit('getAllSuccess', response.data)
                    //console.log(response.data);
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getLocationRecords({commit}, location){
            const path = '/collections/' + location.collection_id + '/locations/' + location.id + '/records/'
            return http.get(path)
                .then(response => {
                    if(!response.data) return [];
                    return response.data;
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        setCurRecord({commit}, route){
            http.get(route)
            .then(response => {
                //console.log("got all records")
                commit('setRecord', response.data)
            })
            .catch(function (error) {
                commit('getAllFailure', error)
                commit('RAISE_ERROR', error, {root: true})
            })
        },

        create({ commit }, {route, record}) {
            return http.post(route, record)
                .then(response => {
                    //console.log("SUCCESS: ",response.data)
                    commit('addNew', response.data)
                })
                .catch(function (error) {
                    let _err = {
                        data: error.response.data,
                        status: error.response.status,
                        headers: error.response.headers
                    }
                    //console.log(_err)
                    commit('getAllFailure', _err)
                })
        },

        update({commit},{route,record}){
            return http.put(route, {record: record})
            .then(response=> {
                console.log("SUCCESS: ",response.data) 
               
            })
            .catch(function (error) {
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        },

        destroy({commit},{route,record}){
            var id = record.id
            return http.delete(route)
            .then(() => {
                //console.log(response)
                commit('destroy', id)
            })
            .catch(function(error){
                let _err = {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers
                }
                //console.log(_err)
                commit('getAllFailure', _err)
            })
        }
    },
    // getters:{
    //     getMobileRecords: state =>{
    //         console.log("getting mobile records")
    //         return state.all.items.filter(record => record.showOnMobile)
    //     }
    // }

}