import http from '../services/http-common'
//import signed_url from '../services/s3-signed-upload'

const createUser = (context, data) => {
    //console.log(data);
    http.post("/signup", data)
        .then(response =>{
            //console.log(response);
            if(response.data.errors){
                //console.log("errors found in response")
                let _err = {
                    data: response.data.errors,
                    status: 'failed-registration'
                }
                context.commit('RAISE_ERROR', _err)
            }
            else{
                //console.log("SUCCESS: " + response.data)
            }
        })
        .catch(function(error){
            let _err = {
                data: error.response.data.status.message,
                status: error.response.status,
                headers: error.response.headers
            }
            context.commit('RAISE_ERROR', _err)
        })
}

const login = (context, user) =>{
    return http.post("/login", user)
        .then(response =>{
            //console.log(response.data.data);
            let _token = response.headers.authorization
            localStorage.setItem('token', _token)

            let u = response.data.data;
            localStorage.setItem('user_id', u.id);
            localStorage.setItem('user_perm', u.permission_level);

            context.commit('LOG_IN',{token: _token, user: u})
            context.commit('RAISE_SUCCESS', 'Successfully Logged in!')
            //console.log("success log in")
        })
        .catch(function(error){
            context.commit('RAISE_ERROR', error)
        })
}

const logout = (context) => {
    return http.delete("/logout")
        .then(() => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id")
            localStorage.removeItem('user_perm')
            context.commit('LOG_OUT')
            context.commit('RAISE_SUCCESS', 'Successfully Logged out!')
        })
        .catch(function(error){
            context.commit('RAISE_ERROR', error)
        })
}

// const getSignature = ()=>{
//     signed_url();
//     //let sig = signed_url();
//     //console.log(context + " getting url" + sig)
// }

const showErrorMessage = (context, msg)=>{
    context.commit('RAISE_MSG', msg)
}

const showSuccessMessage = (context, msg)=>{
    context.commit('RAISE_SUCCESS', msg)
}

export default {
    createUser,
    logout,
    login,
    //getSignature,
    showErrorMessage,
    showSuccessMessage
}