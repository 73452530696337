import http from "../services/http-common";

export const users ={
    namespaced: true,
    state:{
        all:{},
        active:{}
    },
    mutations:{
        // getAllRequest(state) {
        //     state.all = { loading: true }
        // },
        getAllSuccess(state, users) {
            state.all = { items: users }
        },
        getAllFailure(state, error) {
            state.all = { error }
        },
        setActiveUser(state, user){
            state.active = user;
        },
        destroy(state, idx){
            if(state.all.items){
                const filter = state.all.items.filter((item)=>{
                    return item.id !== idx
                })

                state.all.items = filter;
            }
        },
    },
    actions: {
        getUsers({commit}){
            // commit('getAllRequest')
            //console.log("getting all users")
            http.get('/users/')
                .then(response =>{
                    commit('getAllSuccess', response.data)
                    //console.log(response.data);
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        getUser({commit}, id){
            // commit('getAllRequest')
            //console.log("getting all users")
            return http.get('/users/'+id)
                .then(response =>{
                    commit('setActiveUser', response.data)
                    //console.log(response.data);
                    return response.data
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        },
        destroy({commit},user){
            var id = user.id;
            http.delete('/users/'+id)
                .then(() =>{
                    //console.log(response);
                    commit('destroy', id)
                })
                .catch(function (error) {
                    commit('getAllFailure', error)
                    commit('RAISE_ERROR', error, {root: true})
                })
        }
    }
}