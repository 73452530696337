const UPDATE_COLLECTIONS = (state, payload) =>{
    state.collections = payload
}

const RAISE_MSG = (state, msg) =>{
    state.status = 'error'
    //state.error = error
    state.flash_msg = msg
}

const RAISE_ERROR = (state, error) =>{
    state.status = 'error'
    state.error = error

    if(error.response) state.flash_msg = error.response.data
}

const AWAIT_RESPONSE = (state, loading) =>{
    state.loading = loading
}

const RAISE_SUCCESS = (state, msg)=>{
    state.status = 'success'
    state.loading = false
    state.error = {}
    state.flash_msg = msg
}

const DISMISS_ERROR = (state) =>{
    state.status = 'none'
    state.error = {}
    state.flash_msg = ''
}

const LOG_IN = (state, {token, user}) =>{
    state.token = token
    state.status = 'success'
    state.loggedin = true
    state.user_id = user.id;
    state.user_permissions = user.permission_level;
}

const LOG_OUT = (state) =>{
    state.token = ''
    state.status = 'success'
    state.loggedin = false
    state.user_id = -1;
    state.user_permissions = -1;
}

export default {
    UPDATE_COLLECTIONS,
    RAISE_MSG,
    RAISE_ERROR,
    DISMISS_ERROR,
    LOG_IN,
    AWAIT_RESPONSE,
    RAISE_SUCCESS,
    LOG_OUT
}