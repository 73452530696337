import axios from "axios";
import authHeader from "./auth-header";
import index from "../store/index.js";
import router from '../router'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT, 
  headers: authHeader()
});

//Checks for auth token in local storage before every Request
http.interceptors.request.use(
  config => {
    config.headers = authHeader()

    logoutIfTokenExpired();

    return config;
   }
  ,
  error => {
    return Promise.reject(error);
  }
);

//uses responses to check for errors
http.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    if(error.response.status == 401){
      index.dispatch("logout");
      router.push('/');
    }
    return Promise.reject(error);
  }
)
export default http;  

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export function logoutIfTokenExpired(){
  //console.log("checking expiration");
  const auth = authHeader().Authorization;
  if(auth != null){
    const {exp} = parseJwt(auth);
    if(Date.now() >= exp * 1000){
      //console.log("Token is expired. Logging out...");

      localStorage.removeItem("token")

      index.dispatch("logout");
      router.push('/');
    }
  }
}